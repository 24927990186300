import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button } from "antd";
import { apiLogin } from "../../apis/Auth";
import { setLocalStorage } from "utils/CommomFunction";
import { useHistory } from "react-router-dom";
// import { firebaseMessaging } from "firebaseInit";
import {
  isContainSpace,
  isContainSpecialCharacters,
  isValidVietnamese,
} from "utils/Validation";
import PopupWarningFirebase from "components/Notification/PopupWarningFirebase";
import { useTranslation } from "react-i18next";
import Container from "../../layouts/Container";
import { ThemeContext } from "handlers/ThemeContext";
const IP = process.env.REACT_APP_URL_IP;

const LoginForm = ({ checkNotificationPermission = () => {} }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const refPopupWarningFirebase = React.useRef(null);

  // useEffect(() => {
  //   firebaseMessaging
  //     .getToken({
  //       vapidKey:
  //         "BHvwoHJ48Nr-9gLJqGbrFyve1HSwSxFkSaBIYMeq9T1mQ-8B_H-jDpQp0TvXClPHFrMkkejlDOhkOwrbIFTEM2A",
  //     })
  //     .then((firebaseToken) => {
  //       localStorage.setItem("fcmToken", firebaseToken);
  //     });
  // }, []);

  const onFinish = async (values) => {
    // checkNotificationPermission();
    // const fcmToken = await localStorage.getItem("fcmToken");
    // if(!fcmToken){
    //   refPopupWarningFirebase?.current?.show?.();
    //   return;
    // };

    setIsLoading(true);
    const dataLogin = { ...values };
    apiLogin(dataLogin).then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data && res.data.generateMerchantToken) {
        setError(false);
        // setLocalStorage(
        //   "accept_order",
        //   res.data.generateStaffToken.accept_order
        // );
        // setLocalStorage("address", res.data.generateStaffToken.address);
        // setLocalStorage("lastname", res.data.generateStaffToken.lastname);
        // setLocalStorage("restaurant", res.data.generateStaffToken.restaurant);
        // setLocalStorage("storeId", res.data.generateStaffToken.id);
        setLocalStorage("access_token", res.data.generateMerchantToken.token);
        // setLocalStorage("weekday", res.data.generateStaffToken.weekday);
        // setLocalStorage(
        //   "store_status",
        //   res.data.generateStaffToken.store_status
        // );
        // setLocalStorage("weekend", res.data.generateStaffToken.weekend);
        history.push("/home");
      } else {
        setError(true);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateUsername = (value, field) =>
    isContainSpace(value, field).then((res) =>
      res ? isValidVietnamese(value, field) : ""
    );
  const validatePassword = (value, field) =>
    isContainSpace(value, field).then((res2) =>
      res2 ? isContainSpecialCharacters(value, field) : ""
    );
  const { t, i18n } = useTranslation();
  const { isLightMode } = useContext(ThemeContext);
  return (
    <Container>
      <Form
        name="basic"
        className={`login-form ${isLightMode ? "light" : ""}`}
        style={{ margin: "none" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={`login-top ${isLightMode ? "light" : ""}`}>
          <div className={`login-box ${isLightMode ? "box-light" : ""}`}>
            <div className="login-header">
              <div className="header-title">
                <h3>Welcome back</h3>
              </div>
            </div>
            <span
              className={`input-name-text ${isLightMode ? "lightText" : ""}`}
            >
              {t("Shop Code")}
            </span>
            <Form.Item name="username" required={false}>
              <Input
                className={`login-input ${
                  isLightMode ? "login-input-light" : ""
                }`}
                placeholder={t("storeId")}
              />
            </Form.Item>
            <span
              className={`input-name-text ${isLightMode ? "lightText" : ""}`}
            >
              {t("Password")}
            </span>
            <Form.Item name="password">
              <Input.Password
                className={`login-input ${
                  isLightMode ? "login-input-light" : ""
                }`}
                placeholder={t("password")}
              />
            </Form.Item>
            {error && (
              <div style={{ color: "white" }} className="err">
                <span>{t("loginError")}</span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 20,
                fontSize: 16,
                color: "#CC7D00",
                fontWeight: 600,
              }}
            >
              <span>{t("Forgot Password")}</span>
            </div>
            <Form.Item className="login-button-container">
              <Button
                loading={isLoading}
                className="login-button"
                htmlType="submit"
              >
                {t("loginText")}
              </Button>
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            fontSize: 17,
            marginTop: 30,
            color: isLightMode ? "black" : "white",
          }}
        >
          <span>Having troubles? </span>
          <span
            style={{
              color: "#CC7D00",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => history.push("/contact")}
          >
            Contact Levinci Support
          </span>
        </div>
        <div style={{ marginTop: 30, color: isLightMode ? "black" : "white" }}>
          <span>By continuing, you confirm that you agreed with our</span>
        </div>
        <div>
          <span
            style={{
              color: "#CC7D00",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => history.push("/contact")}
          >
            Terms and Conditions.
          </span>
        </div>
        <div style={{ marginTop: 20 }}>
          <span
            style={{
              color: isLightMode ? "black" : "white",
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            Version 1.0.0.1
          </span>
        </div>
      </Form>

      <PopupWarningFirebase ref={refPopupWarningFirebase} />
    </Container>
  );
};

export default LoginForm;
