import React, { useState } from "react";
import { Modal, Button, Radio, Input } from "antd";
import "./index.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import backIcon from "../../assets/back-button.png";
import { apiCancelOrder, apiApproveRefund } from "../../apis/Order";
import { customNotification } from "utils/CommomFunction";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;
const { info } = Modal;

export const RejectOrderModal = (props) => {
  const {
    isShowModalRejectOrder,
    closeModalRejectOrder,
    dataOrder,
    reload,
    submitRejectOrder,
    returnModal,
  } = props;
  const [selectedRadio, setSelectedRadio] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [comment, setComment] = React.useState(" ");
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const handleCancelOrder = () => {
    if (!selectedRadio) {
      return info({
        icon: <></>,
        content: <span>{t("reasonCancelOrder")}</span>,
        onOk() {},
        onCancel() {},
      });
    }
    if (selectedRadio === 3 && note === "") {
      return info({
        icon: <></>,
        content: <span>{t("reasonCancelOrder")}</span>,
        onOk() {},
        onCancel() {},
      });
    }

    setLoadingButton(true);

    apiCancelOrder({
      orderId: dataOrder?.id,
    }).then((res) => {
      setLoadingButton(false);

      if (res.errors && res.errors?.length > 0) {
        alert(res.errors[0]?.message);
        return;
      }

      if (!res.errors && res.data) {
        if (dataOrder?.payment_method_code !== "cashondelivery") {
          info({
            icon: <></>,
            title: <span style={{ fontWeight: "bold" }}>{t("success")}</span>,
            content: t("refundSucess"),
            onOk() {
              submitRejectOrder();
              setSelectedRadio(null);
              setNote("");
            },
            onCancel() {
              submitRejectOrder();
              setSelectedRadio(null);
              setNote("");
            },
          });
        } else {
          customNotification(
            "success",
            `${t("order")} ${dataOrder?.order_number} ${t("canceled")}`
          );
        }
        submitRejectOrder();
        setSelectedRadio(null);
        setNote("");
      }
    });
  };

  const title = (
    <div style={{ borderBottom: "0.5px solid #d3cbcb", paddingBottom: 20 }}>
      <h1>
        {t("refuseOrder")} #{dataOrder?.order_number}
      </h1>
      <h2>{t("reasonCancelOrder")}</h2>
    </div>
  );
  const footer = (
    <div className="modal-button-container">
      <div className="modal-button-container pending"></div>
      <Button
        loading={loadingButton}
        className="modal-button-confirm"
        onClick={handleCancelOrder}
      >
        {!loadingButton && (
          <div className="modal-text-confirm">{t("confirnRefuse")}</div>
        )}
      </Button>
      <div className="modal-button-container"></div>
      <Button
        className="modal-button-back"
        onClick={() => {
          closeModalRejectOrder();
          setSelectedRadio(null);
          setNote("");
          returnModal();
        }}
      >
        <div className="modal-text-back">
          <img
            src={backIcon}
            style={{ width: "16px", height: "16px", marginRight: 5 }}
          ></img>
          <div style={{ fontWeight: "bold" }}>{t("goback")}</div>
        </div>
      </Button>
    </div>
  );
  const closeIcon = (
    <span>
      <CloseCircleFilled
        style={{
          color: "#e31837",
          marginLeft: "70px",
          fontSize: 46,
          backgroundColor: "#ffffff",
          borderRadius: 50,
        }}
      />
    </span>
  );

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      zIndex={1000}
      footer={footer}
      className="modal-container modal-reject-order"
      title={title}
      visible={isShowModalRejectOrder}
      onCancel={() => {
        closeModalRejectOrder();
        setSelectedRadio(null);
        setNote("");
      }}
      closeIcon={closeIcon}
    >
      <div
        className="modal-reject-flex"
        onClick={() => {
          setSelectedRadio(1);
          setComment(t("orderIsNotFeasible"));
        }}
      >
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>
            {t("orderIsNotFeasible")}
          </div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 1} />
          </span>
        </div>
      </div>
      <div
        className="modal-reject-flex"
        onClick={() => {
          setSelectedRadio(2);
          setComment("Bom");
        }}
      >
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>Bom</div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 2} />
          </span>
        </div>
      </div>
      <div className="modal-reject-reason" onClick={() => setSelectedRadio(3)}>
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>{t("otherReason")}</div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 3} />
          </span>
        </div>
      </div>
      {selectedRadio === 3 && (
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={4}
          placeholder={t("pleaseEnterReason")}
          style={{ borderRadius: 10, padding: 10 }}
        />
      )}
    </Modal>
  );
};
